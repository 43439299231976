/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap'); */
:root {
    --speed: 500ms;
}

*{
    margin:0;
    padding:0;
    box-sizing: border-box;
    font-family: 'Monserrat', sans-serif;
    font-style: normal;
}

body{
    min-height: 100vh;
    background: rgba(0,0,42,1);
    background: -moz-linear-gradient(left, rgba(0,0,42,1) 0%, rgba(2,0,42,1) 10%, rgba(12,0,42,1) 51%, rgba(2,0,8,1) 91%, rgba(0,0,0,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,0,42,1)), color-stop(10%, rgba(2,0,42,1)), color-stop(51%, rgba(12,0,42,1)), color-stop(91%, rgba(2,0,8,1)), color-stop(100%, rgba(0,0,0,1)));
    background: -webkit-linear-gradient(left, rgba(0,0,42,1) 0%, rgba(2,0,42,1) 10%, rgba(12,0,42,1) 51%, rgba(2,0,8,1) 91%, rgba(0,0,0,1) 100%);
    background: -o-linear-gradient(left, rgba(0,0,42,1) 0%, rgba(2,0,42,1) 10%, rgba(12,0,42,1) 51%, rgba(2,0,8,1) 91%, rgba(0,0,0,1) 100%);
    background: -ms-linear-gradient(left, rgba(0,0,42,1) 0%, rgba(2,0,42,1) 10%, rgba(12,0,42,1) 51%, rgba(2,0,8,1) 91%, rgba(0,0,0,1) 100%);
    background: linear-gradient(to right, rgba(0,0,42,1) 0%, rgba(2,0,42,1) 10%, rgba(12,0,42,1) 51%, rgba(2,0,8,1) 91%, rgba(0,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00002a', endColorstr='#000000', GradientType=1 );
}

.navigation {
    position: fixed;
    top:50px;
    width: 60px;
    height: 100%;
    /* background: #3e0748; */
    background-color: rgba(0,0,42,0.4);
    background-image: url('../img/Fondo\ transparente\ M.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: -100px;
    border-right: 1px solid #D64123;
    transition: 0.5s;
    overflow: hidden;
    letter-spacing: 4px;
    z-index: 100;
    display: none;
}
.ubicacion{
    letter-spacing: 4px;
}

.fondo_oscuro {
    position: fixed;
    top:50px;
    width: 0;
    height: 100%;
    right:0;
    transition: 0.5s;
    overflow: hidden;

    background: rgba(0,0,42,1);
    background: -moz-linear-gradient(left, rgba(0,0,42,1) 0%, rgba(2,0,42,1) 10%, rgba(12,0,42,1) 51%, rgba(2,0,8,1) 91%, rgba(0,0,0,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0,0,42,1)), color-stop(10%, rgba(2,0,42,1)), color-stop(51%, rgba(12,0,42,1)), color-stop(91%, rgba(2,0,8,1)), color-stop(100%, rgba(0,0,0,1)));
    background: -webkit-linear-gradient(left, rgba(0,0,42,1) 0%, rgba(2,0,42,1) 10%, rgba(12,0,42,1) 51%, rgba(2,0,8,1) 91%, rgba(0,0,0,1) 100%);
    background: -o-linear-gradient(left, rgba(0,0,42,1) 0%, rgba(2,0,42,1) 10%, rgba(12,0,42,1) 51%, rgba(2,0,8,1) 91%, rgba(0,0,0,1) 100%);
    background: -ms-linear-gradient(left, rgba(0,0,42,1) 0%, rgba(2,0,42,1) 10%, rgba(12,0,42,1) 51%, rgba(2,0,8,1) 91%, rgba(0,0,0,1) 100%);
    background: linear-gradient(to right, rgba(0,0,42,1) 0%, rgba(2,0,42,1) 10%, rgba(12,0,42,1) 51%, rgba(2,0,8,1) 91%, rgba(0,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00002a', endColorstr='#000000', GradientType=1 );
}

.navigation:hover,
.navigation.active{
    width: 300px;
    display: block;
}
.navigation ul{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
}
.navigation ul li{
    position: relative;
    width: 100%;
    list-style: none;
}
.navigation ul li:hover span:after{
    content: "";
    display: block;
    border-bottom: 2px solid#D64123;
}

.container_principal.active{
    padding-left: 300px;
}
.navigation ul li a{
    position: relative;
    display: block;
    width: 100%;
    display: flex;
    text-decoration: none;
    color: #fff;
}
.navigation ul li a .icon{
    position: relative;
    display: block;
    min-width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
}
.icon{
    display: flex!important;
    align-items: center;
    justify-content: center;
}
.icon img{
    width: 30px;
}
.navigation ul li a .icon .fa{
    font-size: 24px;
}
.navigation ul li a .title{
    position: relative;
    display: block;
    padding: 0 10px;
    height: 60px;
    line-height: 60px;
    text-align: start;
    white-space: nowrap;
}
.toggle{
    position: relative;
    top:0;
    right: 0;
    width: 30px;
    height: 50px;
    /* background: #330748; */
    cursor:pointer;
}
/* .toggle.active{
    background: #D64123;
} */
.toggle:before{
    content:url('../img/Icon\ Menu\ Bandin.svg');
    font-family: fontAwesome;
    position: absolute;
    width: 100%;
    height: 100%;
    line-height: 60px;
    text-align: center;
    font-size: 24px;
    color:#FFF;
}
.toggle.active:before{
    content:url('../img/Cerrar.svg');
}

.acceso_rapido{
    width: 100%;
    height: 50px;
    background-image: url('../img/Fondo-Fijo.jpg');
    position: relative;
    background-size: cover;
    /* bottom:80px; */
    z-index: 102;
}
.acceso_rapido img{
    width: 40px;
    padding-top:5px;
}

/* Animaciones */
.menu-primary-enter{
    position: absolute;
    transform:translateX(-110%);
}
.menu-primary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
}
.menu-primary-exit {
    position: absolute;
}
.menu-primary-exit-active {
    transform: translateX(-110%);
    transition: all var(--speed) ease;
}

.menu-secondary-enter{
    transform:translateX(-110%);
}
.menu-secondary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
}
.menu-secondary-exit {
    
}
.menu-secondary-exit-active {
    transform: translateX(-110%);
    transition: all var(--speed) ease;
}