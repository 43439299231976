.filters {
    margin-inline-start: 60px;
    display: grid;
    grid-template-columns: 3fr 6fr 6fr;
    grid-column-gap: 10px;
    align-items: center;
    color: white;
    font-size: 10px;
}

@media (min-width: 1024px) {
    .filters {
        grid-template-columns: 3fr 6fr 6fr;
        font-size: 15px;
    }
}
/* Inicia: filter--numero--inmuebles */

.filter--numero--inmuebles {
    display: grid;
    align-items: center;
    grid-column-gap: 5px;
    grid-template-areas: "title title resize"
                         "number image resize";
    grid-template-columns: 60px 30px 15px;
    grid-template-rows: repeat(2, 30px);
}

@media (min-width: 1024px) {
    .filter--numero--inmuebles {
        grid-column-gap: 10px;
        grid-template-columns: 100px 50px 30px;
    }
}

.filter--numero--inmuebles__title {
    grid-area: title;
    grid-column: 1/3;
}

.filter--numero--inmuebles__numberImb {
    grid-area: number;
}

.filter--numero--inmuebles__numberImb:hover,
.filter--numero--inmuebles__numberImb:active {
    cursor: none;
}

.filter--numero--inmuebles__image {
    justify-self: center;
    grid-area: image;
    padding: 3px !important;
    width: 30px !important;
    height: 30px !important;
    box-sizing: border-box !important;
}

.filter--numero--inmuebles__image:hover,
.filter--numero--inmuebles__image:active {
    background: var(--black01);
    cursor: pointer;
    box-shadow: 0 5px 10px #666;
    transform: translateY(4px);
    cursor: pointer;
}

.filter--numero--inmuebles__resize {
    justify-self: center;
    grid-area: resize;
    grid-row: 1/3;
}

/* Termina: filter--numero--inmuebles */


/* Inicia: filter--busqueda */

.filter--busqueda {
    justify-self: center;
    width: 200px;
    display: grid;
    grid-auto-flow: column;
}

.filter--busqueda__button {
    width: 30px;
}

.filter--busqueda__button:hover,
.filter--busqueda__button:active {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.7);
}

/* Termina: filter--busqueda */

/* Inicia: filter--paginacion */

.filter--paginacion {
    justify-self: end;
    text-align: center;
    width: 250px;
    display: grid;
    grid-auto-flow: column
}

.filter--paginacion img {
    height: 30px;
}

.w-30 {
    width: 30px;
}

.self-center {
    justify-self: center;
}

/* Termina: filter--paginacion */