.details--actions {
    justify-self: end;
    display: flex;
    align-items: center;
}

.details--actions img {
    width: 20px;
}

.details--actions p:nth-of-type(1) {
    border: 2px solid #15ffb9ad;
    background: #15ffb9ad;
    border-radius: 4px;
    padding: 5px 10px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
}

.details--border {
    border: 2px solid #15ffb9ad;
    border-radius: 4px;
    padding: 5px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}