.formulario {
    padding: 5px;
    /* box-sizing: border-box; */
}

.formulario__titulo {
    text-align: center;
    background-color: rgba(62, 62, 68, .52);
    padding-block: 10px;
    margin-block: 15px;
    border: 1px solid white;
    border-radius: 4px;
    font-size: 13px;
}

.formulario--descripcion {
    text-align: center;
    margin: 15px 0;
}

.formulario__contenido {
    display: grid;
    grid-gap: 10px;
    align-items: center;
    justify-content: end;
    box-sizing: border-box;
}

.formulario__contenido input[type="text"],
.formulario__contenido textarea {
    border: none;
    border-radius: 4px;
    padding: 6px 3px;
}

#form1 {
    grid-template-columns: 20% 55% 15%;
}

#form1 #buttonCA {
    border: 1px solid white;
    border-radius: 4px;
    background-color: transparent;
    color: var(--white01);
    padding: 6px 3px;
}

#form1 #gralTitleInput {
    grid-column: 2/4;
}

#form1 #gralDescTitle {
    grid-column: 1/4;
}

#form1 #gralDescTA {
    grid-column: 1/4;
}

#form1 #gralGuardar {
    grid-column: 2/4;
    justify-self: end;
}

#form2 {
    grid-template-columns: 1fr 2fr 5fr 2fr 1fr;
}

#form2 #buttonPriorizar {
    grid-column: 3/4;
}

#form2 #inputGuardar {
    grid-column: 4/6;
    justify-self: end;
}

#form3 {
    grid-template-columns: 20% 70%;
}

#form3 #buttonsN {
    grid-column: 1/3;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

#buttonsN button {
    border: 1px solid white;
    border-radius: 4px;
    background-color: transparent;
    color: var(--white01);
    padding: 10px 15px;

}

#form3 #buttonSave {
    grid-column: 2/3;
    justify-self: end;
}