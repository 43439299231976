.btn {
  width: 30px;
  height: 30px;
  border: 1px solid var(--gray01);
  border-radius: 5px;
  background: transparent;
  color: var(--white01);
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Button styles */

.btn--currency {
  width: 30px;
  height: 30px;
  font-size: 11px;
  text-transform: uppercase;
  transition: 0.2s;
}

.btn--currency:hover,
.btn--currency:active {
  background: var(--black01);
  cursor: pointer;
  box-shadow: 0 5px 10px #666;
  transform: translateY(4px);
}

.btn--service {
  padding: 5px 20px;
  border-radius: 3px;
}

.btn--redBorder {
  border-radius: unset;
  width: 10em;
  height: 10em;
  border: unset;
  /* background: url(../assets/jpg-png/Marco\ rojo.png); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  color: white;
  text-transform: uppercase;
  font-family: Montserrat;
  font-size: 12px;
}

.btn--redBorder p {
  max-width: 60%;
}

.btn--redBorder img {
  width: 2.5em;
}

.btn--datos-generales {
    background-color: rgba(153, 134, 117, 0.5);
    color: var(--white01);
    border: 1px solid var(--white01);
    border-radius: 4px;
    padding: 3px 5px;
    max-width: 100%;
}
