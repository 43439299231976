.agregar-generales {
  font-size: 11px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
}

.agregar-generales__contador {
  display: flex;
  align-items: center;
  gap: 25px;
}

.agregar-generales #cuentaInb {
  padding: 10px 20px;
  border-radius: 4px;
  border: 1px solid var(--white01);
}