.transparent{
    background-color: white;
}
.btn-custom-1{
    background-color: rgba(199, 178, 153, 0.29);
    color: #FFF;
    border: 2px solid rgba(255, 255, 255, 0.29);
    font-size: 12px;
}
.btn-custom-1:hover{
    background-color: rgba(199, 178, 153, 0.09);
}
.btn-custom-2{
    background-color: transparent;
    border: 1px solid white;
}
.btn-custom-2:hover{
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid white;
}
.seleccionar-custom-1:hover{
    background-color: rgba(0, 0, 0, 0.2);
}
.btn-custom-3{
    background-color: rgba(255, 255, 255, 1);
    color: #FFF;
    border: 2px solid rgba(255, 255, 255, 1);
    font-size: 12px;
}
.bandin-card{
    background-color: transparent;
    border: 1px solid gray;
}
.bandin-card .card-header, .bandin-card .card-footer{
    background-color: rgba(255, 255, 255, 0.29);
    color:white;
}
.btn-cafe{
    background-color: rgb(187, 136, 91);
    color:white
}
.btn-cafe:hover{
    background-color: rgba(187, 136, 91, 0.8);
}
.btn-negro{
    background-color: rgb(0, 0, 0);
    color:white
}
.btn-negro:hover{
    background-color: rgba(0, 0, 0, 0.8);
}

.contacto_btn_activo {
    background-color: rgba(199, 178, 153, 0.36);
}