.service {
    display: flex;
    gap: 15px;
    font-size: 11px;
}

.service img {
    width: 15px;
}

@media (max-width: 1024px) {
    .service {
        font-size: 15px;
    }

    .service img {
        width: 20px;
    }
}