.photo {
    width: 90%;
    position: relative;
    display: flex;
    flex-direction: column;
    margin: auto;
    border: 2px solid rgb(0, 128, 96);
    box-sizing: border-box;
}

.photo img {
    max-width: 100%;
}

.photo p {
    width: 100%;
    position: absolute;
    bottom: 0;
    margin: auto;
    background: rgba(0, 0, 0, 0.68);
    text-align: center;
    font-size: 10px;
    line-height: 20px;
}

@media (min-width: 1024px) {
    .photo p {
        font-size: 14px;
        line-height: 36px;
    }
}