.inmuebles--content {
  position: relative;
  height: calc(100vh - 350px);
  padding-inline-end: 20px;
  overflow-y: scroll;
  /* display: flex;
  flex-direction: column; */
  box-sizing: border-box;
  /* padding-block-start: 300px; */
  /* padding-block-start: calc(100vh - 20vh); */
}

.inmuebles--agregar--content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inmuebles--agregar {
  height: 70vh;
  display: grid;
  grid-template-columns: 12% 88%;
  justify-content: center;
  align-items: center;  
}

.inmuebles--menu ul {
  list-style: none;
  padding-inline-start: 0;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 15px;
}

.inmuebles--menu li {
  /* align-self: center; */
  width: 9em;
  height: 9em;
  background: url(../img/Marco\ rojo.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 10px;
}

.inmuebles--menu a {
  text-decoration: none;
  color: var(--white01);
  width: 7.7em;
  height: 8em;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.inmuebles--menu a:hover {
  background: rgba(0, 0, 0, 0.32);
}

.inmuebles--menu p {
  margin: 0;
}