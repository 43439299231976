/* .item:nth-of-type(1) {
    margin-block-start: 80vh;
} */

.item {
  border: 1px solid var(--white01);
  margin-block-start: 10px;
  margin-block-end: 10px;
}

.item--content {
  display: grid;
  grid-template-columns: 20% 40% 40%;
  position: relative;
}

.item--title {
  background: url(../img/Fondo\ menu\ rapido.png);
  background-size: cover;
  padding: 10px;
  display: grid;
  grid-template-columns: 40% 20% 40%;
  position: relative;
  font-size: 11px;
}

.item--first--column {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-block-start: 10px;
}

.item--second--column .service,
.item--second--column .item--status__button {
  /* border: 1px solid green; */
  font-size: 10px;
}

.item--second--column .dots--1024 {
  display: none;
}

@media (min-width: 1024px) {
  .item--first-column {
    align-items: center;
    display: unset;
    justify-content: unset;
    align-items: unset;
    margin-block-start: unset;
  }

  .item--second--column {
    padding: 20px;
  }

  .item--second--column .service,
  .item--second--column .item--status__button {
    /* border: 1px solid r  ed; */
    font-size: 11px;
  }

  .item--second--column .dots--768 {
    display: none;
  }

  .item--second--column .dots--1024 {
    display: block;
  }

  .item--third-column {
    padding: 20px;
  }
}

.item--second--column {
  padding: 10px;
}

.item--third-column {
  padding: 10px;
  font-size: 12px;
}

.item--currency {
  display: flex;
  gap: 15px;
  margin-block-end: 20px;
}

.item--company {
  justify-self: end;
}

.item--col-2 {
  display: grid;
  grid-template-columns: 60% 25%;
  box-sizing: content-box;
  column-gap: 15px;
}

.item--services,
.item--status {
  font-size: 11px;
}

@media (min-width: 1024px) {
  .item--services,
  .item--status {
    font-size: 15px;
  }
}

.item--services {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

@media (min-width: 1024px) {
  .item--status__button {
    font-size: 11px;
  }
}

.item--status__button {
  margin-block-end: 10px;
}
