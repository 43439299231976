.galery {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5rem;
}

.galery--selection {
  display: grid;
  grid-template-columns: repeat(3, 150px);
  grid-template-rows: 80px 150px 80px 80px;
  gap: 10px;
  justify-content: center;
}

.galery--buttons {
  display: flex;
  justify-content: space-around;
  margin-block-end: 34px;
}

.fill {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.fill img {
  max-width: 150px;
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
  transition: 0.2s;
}

.fill img:hover,
.fill img:active {
  cursor: pointer;
  box-shadow: 0 5px 10px #666;
  transform: translateY(4px);
}

.galery--screen {
  height: 400px;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid green;
  align-self: center;
  display: flex;
  align-items: center;
}

.galery--screen img {
  max-width: 100%;
}
